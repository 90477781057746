<template>

  <el-container style="height:calc(100vh); background-color:#f9f9f9;">
    <el-header class="header">
      <span class="left el-icon-arrow-left" @click="returnOn"></span>
      换购专区
    </el-header>
    <el-container>
      <el-main style="height:calc(100vh);width:75%;border-right:1px solid #e9e9e9;">
        <el-container style="height:calc(94vh); background-color:#f9f9f9;">
          <el-header class="header-search">
            <div class="search">
              <el-input clearable placeholder="输入商品名称" prefix-icon="el-icon-search" v-model="searchVal"
                @keyup.enter.native="searchChange">
              </el-input>
              <el-button type="primary" @click="searchChange">搜索</el-button>
            </div>
          </el-header>
          <el-main>
            <el-row :gutter="15" style="padding:10px 10px 0 0;">
              <el-col class="cursor" :span="rowCol" v-for="(item ,i) in duihuanList" :key="i">
                <div :class="activeItemList.indexOf(item)!==-1?'order-card order-card1':'order-card'"
                  @click="activeList(item)">
                  <div class="card-name">{{ item.name}}</div>
                  <div class="btm">
                    <div class="btm-time">{{  item.shopspecs_id}}</div>
                    <div class="btm-price">
                      <strong>{{ item.integ}}</strong>积分
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-main>
        </el-container>
      </el-main>
      <el-main style="height:calc(100vh);width:25%;min-width:350px; background-color:#fff;padding:0 !important;">
        <el-container style="height:calc(94vh);">
          <el-header class="header-right">
            <div class="title">已选商品</div>
            <div class="integral iconfont icon-jifen">可用积分 {{  vipuser.score}}</div>
          </el-header>
          <el-main>
            <div class="order">
              <div class="order-body">
                <div class="goods-card cursor" v-for="(item ,i) in activeItemList" :key="i">
                  <div class="card-t">
                    <div class="t-name">{{  item.name}}</div>
                    <!-- <div>
                      <span class="t-del iconfont icon-cha" @click="delOrderGoods"></span>
                    </div> -->
                  </div>
                  <div class="card-c">
                    <div class="c-time">{{ item.shopspecs_id }}</div>
                  </div>
                  <div class="card-b">
                    <div class="b-name">
                      <div class="stepper">
                        <el-input-number v-model="item.num" :precision="0" :min="1" :max="10" @change="numChange">
                        </el-input-number>
                      </div>
                    </div>
                    <div class="b-change">
                      <b>{{ item.integ}}</b>积分</div>
                  </div>
                </div>
              </div>
            </div>
          </el-main>
          <div class="footer">
            <div class="top">
              <span class="span">共{{activeItemList.length}}项</span> 共计：<span><b>{{ allmoney.toFixed(2)}}</b>积分</span>
            </div>
            <el-button type="primary" round @click="confirmChangeGoods">确认</el-button>
          </div>
        </el-container>
      </el-main>
    </el-container>

  </el-container>
</template>

<script>
import { mapState } from 'vuex'
  export default {
    data() {
      return {
        searchVal: '',
        activeItemList: [],
        innerWidth: document.documentElement.clientWidth,
        rowCol: 6,
        stepperNum: 1,
        duihuanList:[],//兑换商品列表
        allmoney:0,
      }
    },
    computed: {
      ...mapState(['vipuser'])
    },
    watch: {
      innerWidth(val) {
        if (val >= 1440) {
          this.rowCol = 6
        } else if (val >= 1180) {
          this.rowCol = 8
        } else if (val >= 900) {
          this.rowCol = 12
        } else if (val <= 900) {
          this.rowCol = 24
        }
      }
    },
    mounted() {
      var that = this;
      if (that.innerWidth >= 1440) {
        that.rowCol = 6
      } else if (that.innerWidth >= 1180) {
        that.rowCol = 8
      } else if (that.innerWidth >= 900) {
        that.rowCol = 12
      } else if (that.innerWidth <= 900) {
        that.rowCol = 24
      }
      window.onresize = () => {
        return (() => {
          window.fullWidth = document.documentElement.clientWidth;
          that.innerWidth = window.fullWidth; // 宽
        })()
      };
      this.getIntList()
    },
    methods: {
      //点击兑换
      duihuanPay(){
        this.$http.post('api/store/scoreorder/convpay',{
          user_id:this.vipuser.id,
          sid:this.activeItemList,
          score:this.allmoney
        }).then(res =>{
          this.$message.success(res.data.msg)
          setTimeout(() => {
            this.$router.go(-1)

          }, 1500)
        })
      },
      //添加数量计算价格
      numChange(){
        this.comMoney()
      },
      //获取换购列表
      getIntList(){
        this.$http.post('api/store/scoreorder/shoplist',{
          keyword:this.searchVal
        }).then(res =>{
          this.duihuanList = res.data.data
          for(let i in this.duihuanList){
            this.duihuanList[i]['num'] = 1
          }
        })
      },
      // 返回上级
      returnOn() {
        this.$router.go(-1)
      },
      // 搜索
      searchChange() {
        this.getIntList()
      },
      // 选中商品
      activeList(item) {
        console.log(this.activeItemList.indexOf(item));
        if (this.activeItemList.indexOf(item) === -1) {
          this.activeItemList.push(item)
        } else {
          this.activeItemList.splice(this.activeItemList.indexOf(item), 1)
        }
        this.comMoney()
      },
      //计算商品总价格
      comMoney(){
        let mo = 0
        for(let k in this.activeItemList){
          mo += Number(this.activeItemList[k].num)*Number(this.activeItemList[k].integ)
        }
        this.allmoney = mo
      },
      // 删除商品
      delOrderGoods() {
        this.$confirm('确认要删除换购商品吗？', '删除确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          center: true
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {

        });
      },
      // 换购商品
      confirmChangeGoods() {
        if(this.activeItemList.length != 0){
          this.$confirm('确认要换购所选商品吗？', '换购确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            center: true
          }).then(() => {
            this.duihuanPay()
          }).catch(() => {

          });
        }else{
          this.$message.warning('请选择要兑换的商品')
        }
        
      },
    }
  };

</script>
<style lang="less" scoped>
  // 头部
  .el-header {
    position: relative;
    z-index: 1;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;

    .left {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      color: #46a6ff;
      font-size: 24px;
    }
  }

  // 左侧商品卡片
  .header-search {
    height: 60px !important;
    background-color: #f9f9f9;
    border-bottom: none;

    .search {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 10px 0;

      .el-button--primary {
        height: 40px;
        margin-left: -5px;
        z-index: 1;
        font-size: 12px;
        border-radius: 0 5px 5px 0;
        border: 1px solid #3d96f2;
      }

      .el-button--primary:focus,
      .el-button--primary:hover,
      .el-button:active,
      .el-button:focus,
      .el-button:hover {
        color: #fff;
        border-color: #409eff;
        background-color: #409eff;
      }

      /deep/ .el-input__inner {
        height: 40px;
        border: 1px solid #dcdfe6;
        border-right: 0;
        border-radius: 5px 0 0 5px;

        &:focus,
        &:hover {
          border-color: #dcdfe6;
        }
      }
    }
  }

  .order-card {
    box-shadow: 0px 0px 4px #ccc;
    margin: 8px 0;
    padding: 15px;
    border-radius: 10px;
    background-color: #f4f4f4;
    overflow: hidden;
    border: 1px solid #f4f4f4;

    .card-name {
      font-size: 14px;
      color: #666;
      margin-bottom: 30px;
    }

    .btm {
      color: #999;
      font-size: 12px;

      .btm-time {
        float: left;
      }

      .btm-price {
        float: right;
        color: #f56c6c;

        strong {
          margin-left: 10px;
          font-size: 14px;
        }
      }
    }
  }

  .order-card1 {
    border: 1px solid #409eff;
    background-color: #e6eff9;
    box-shadow: none;

  }

  .header-right {
    height: auto !important;
    color: #666;
    padding: 0 10px 10px;
    background-color: #fff;
    border-bottom: none;

    .title {
      text-align: left;
    }

    .integral {
      text-align: left;
      background-color: #333333;
      color: #fff;
      padding-left: 10px;
      border-radius: 5px;
      font-size: 14px;

      &::before {
        margin-right: 5px;
        font-size: 18px;
      }
    }
  }

  .order {
    padding-bottom: 20px;

    .order-body {
      .goods-card {
        background-color: #fff;
        box-shadow: 0 0 4px #ccc;
        border-radius: 10px;
        margin-top: 10px;
        padding: 10px;
        color: #666;

        .card-t {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;

          .t-name {
            width: 70%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          span {
            display: inline-block;
            margin-left: 10px;
            color: #ccc;
          }
        }

        .card-c {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          .c-price {
            font-size: 12px;
            color: #999;

            span {
              color: #666;
              font-size: 14px;
            }
          }
        }

        .card-b {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .b-name {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .stepper {

              /deep/ .el-input-number {
                width: 100px;
                height: 24px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .el-input-number__decrease,
                .el-input-number__increase {
                  width: 24px;
                  height: 24px;
                  box-sizing: border-box;
                  z-index: 1;
                  line-height: 20px;
                  border-radius: 100%;
                  border: 1px solid #666;
                  color: #666;
                  font-weight: bold;
                }

                .el-input {
                  width: 100px;
                  height: 34px;

                  .el-input__inner {
                    height: 24px;
                    line-height: 24px;
                    padding: 0 15px;
                    border-color: #73a0fa;
                    color: #666;
                    font-weight: bold;
                    border: none;
                  }
                }
              }
            }
          }

          .b-change {
            color: #f56c6c;
            font-size: 12px;

            b {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .footer {
    padding: 0 10px;

    .top {
      padding: 15px 0;
      border-bottom: 1px solid #f2f2f2;
      color: #666;

      .span {
        margin-right: 10px;
        color: #666;
        font-size: 14px;

      }

      span {
        color: #f56c6c;
        font-size: 12px;

        b {
          font-size: 14px;
        }
      }
    }

    .el-button {
      margin-top: 5px;
      width: 100%;
    }
  }

  /deep/ .el-main {
    padding: 0 10px !important;

    .el-row {
      padding: 0 10px !important;


    }

  }

</style>
